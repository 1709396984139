<template>
   <div class="col-12 px-0 mb-1">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-lg text-center text-lg-start">
                  <h1 class="font-15 mb-0 limitador-1 text-capitalize">{{ produto.descricaoProduto == null ? 'Sem nome' : produto.descricaoProduto }}</h1>
                  <p class="font-10 mb-0 limitador-1 text-secondary">
                     <span><i class="far fa-hashtag font-9 me-1"></i>{{ produto.id }}</span>
                     <span class="ms-3"><i class="far fa-tag font-9 me-1"></i>{{ produto.infAdProd == null ? '-' : produto.infAdProd }}</span>
                  </p>
                  <div class="row row-cols-2 row-cols-xxl-3 align-items-center mt-1">
                     <div class="px-0 d-none d-xxl-block">
                        <p class="font-12 mb-0 limitador-2">
                           <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1">Código:</strong>
                           <span>{{ produto.codigoEanProduto == null ? 'Sem código' : produto.codigoEanProduto }}</span>
                        </p>
                     </div>
                     <div class="px-0">
                        <p class="font-12 mb-0 limitador-2">
                           <i class="far fa-money-bill-wave-alt color-theme font-10 me-1"></i><strong class="subtitle me-1">Unitário:</strong>
                           <span><small>R$ </small>{{ produto.unitario == null ? '0,00' : parseFloat(produto.unitario).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                        </p>
                     </div>
                     <div class="px-0">
                        <p class="font-12 mb-0 limitador-2">
                           <i class="far fa-money-bill-wave-alt color-theme font-10 me-1"></i><strong class="subtitle me-1">Total:</strong>
                           <span><small>R$ </small>{{ produto.total == null ? '0,00' : parseFloat(produto.total).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="wpx-115 text-center mx-auto mx-lg-0 mt-2 mt-lg-0" v-if="showEdit">
                  <label><i class="far fa-box color-theme font-10 me-1"></i> Quantidade</label>
                  <input type="text" class="form-control" v-model="produto.qtd" />
               </div>
               <div class="wpx-115 font-15 text-center mx-auto mx-lg-0 mt-2 mt-lg-0" v-else>
                  <span class="d-block lh-1">
                     {{ parseFloat(produto.qtd).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 3}) }}
                     <small class="font-11">{{ produto.unidadeProduto == null || String(produto.unidadeProduto).trim().length == 0 ? '-' : produto.unidadeProduto }}</small>
                  </span>
                  <small class="font-12"><i class="far fa-box color-theme font-10 me-1"></i> Quantidade</small>
               </div>
               <div class="wpx-125 text-center mx-auto ms-lg-0 mt-2 mt-lg-0" v-if="showEdit && entradaEstoque == 'Recebimento' && produto.vinculado">
                  <label><i class="far fa-box color-theme font-10 me-1"></i> Estoque</label>
                  <v-select class="w-100 select-sm" :options="produto.estoques" v-model="produto.idEstoque" label="nome" :reduce="e => e.idEstoque" :clearable="false" :searchable="false" placeholder="-">
                     <template #option="{nome, quantidade}">{{ nome }}<small class="d-block"><i class="far fa-box font-9 me-1"></i>x{{ quantidade == null ? 0 : parseFloat(quantidade).toFixed(1) }}</small></template>
                     <template v-slot:no-options>
                        <span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Estoque" > "Gerenciamento" para adicionar</small>
                     </template>
                  </v-select>
               </div>
               <div class="wpx-150 text-center mx-auto mx-lg-0 mt-2 mt-lg-0" v-if="showEdit">
                  <input class="form-check-input" :id="'validacao-check-prod'+ index" type="checkbox" v-model="produto.validado" :checked="produto.validado">
                  <label class="cursor-pointer" :for="'validacao-check-prod'+ index" v-if="produto.validado"><i class="fas fa-check font-11 color-theme me-1"></i>Validado</label>
                  <label class="cursor-pointer" :for="'validacao-check-prod'+ index" v-else><i class="fas fa-times text-red me-1"></i>Não validado</label>
               </div>
               <div class="wpx-150 font-15 text-center mx-auto mx-lg-0 mt-2 mt-lg-0" v-else>
                  <span v-if="produto.validado" class="color-theme"><i class="fas fa-check d-block"></i><small>Validado</small></span>
                  <span v-else class="text-red"><i class="fas fa-times d-block"></i><small>Não validado</small></span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Produto',
   props: ['produto', 'index', 'showEdit', 'entradaEstoque'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   watch: {
      'produto.qtd': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 4}).then((valor) => {
            this.produto.qtd = valor
         })
      }
   },
   methods: {
      vincular : function () {
         this.$emit('vincular', this.produto)
      },
      desvincular : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Desvincular produto?',
            text: 'O produto será desvinculado desse código/fornecedor.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'fiscal/desvincularProduto',
                  params: {
                     idItemNota: this.produto.id
                  }
               }).then(() => {
                  this.$emit('atualizar')

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Produto desvinculado!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   },
   mounted() {
      this.produto.qtd = this.produto.qtd == null ? '0.0000' : parseFloat(this.produto.qtd).toFixed(4)
   }
}

</script>

<style scoped>

label:not(.cursor-pointer) {
   font-size: 10.5px !important;
}
input:not(.form-check-input) {
   padding: 3px 9px !important;
   font-size: 12px !important;
   text-align: center;
}

</style>